//
//

document.addEventListener("turbolinks:load", function() {
  let elem = document.querySelector('.questionnaire_log')

  if(!elem) { return }

  apply_wizard_buttons()
})

function apply_wizard_buttons() {
  var container = document.querySelector('.questionnaire_log')
  container
    .querySelector('.btn-next')
    .addEventListener('click', wizard_button_next);

  container
    .querySelector('.btn-previous')
    .addEventListener('click', wizard_button_previous);
}

function wizard_button_next(event) {
  wizard_button_clicked(event, +1)
}

function wizard_button_previous(event) {
  wizard_button_clicked(event, -1)
}

function wizard_button_clicked(event, direction) {
  var container        = event.currentTarget.closest('.questionnaire_log')
  var currentFieldset  = container.querySelector('fieldset:not(.d-none)')
  var buttonNext       = container.querySelector('.btn-next')
  var buttonPrevious   = container.querySelector('.btn-previous')

  if(direction > 0) {
    let checked = currentFieldset.querySelectorAll('input:checked').length;

    if(checked == 0 && !wizard_has_numeric_value(currentFieldset)){
      alert('Pasirinkite atsakymą prieš tęsdami');
      return
    }
  }

  let nextFieldset = direction > 0 ? currentFieldset.nextElementSibling : currentFieldset.previousElementSibling

  if(!nextFieldset) {
    container.submit()
    return
  }

  currentFieldset.classList.   add('d-none')
  nextFieldset.   classList.remove('d-none')

  if(nextFieldset.previousElementSibling) {
    buttonPrevious.classList.remove('invisible')
  } else {
    buttonPrevious.classList.add('invisible')
  }

  var alert_elem = container.closest('.col-12').querySelector('.alert')

  if(alert_elem) {
    alert_elem.classList.add('d-none')
  }
}

function wizard_has_numeric_value(container) {
  var output = false;
  container
    .querySelectorAll('input[type=number]')
    .forEach(function(elem){
      if(elem.value || elem.value === 0) {
        output = true;
      }
    });

  return output;
}
